<template>
  <div>
    <div class="d-flex justify-content-between m-2 m-sm-0">
      <b-dropdown
        v-if="periods && periods.length > 1 && period"
        :text="period.name"
        :variant="dropdown_variant"
        class="dropdown-year flex-fill"
        menu-class="w-275px w-lg-auto"
      >
        <b-dropdown-item
          v-for="(p, i) in periods"
          :key="`year-${i}`"
          @click="onSelectPeriod(p)"
          :active="period.name === p.name"
          >{{ p.name }}</b-dropdown-item
        >
      </b-dropdown>
      <b-dropdown
        v-if="company && currentCompanyId"
        :variant="dropdown_variant"
        class="ml-4 dropdown-company flex-fill"
        menu-class="w-275px w-lg-auto"
      >
      <template v-slot:button-content>
        <fa-icon :icon="company_icon(currentCompanyId)"/>
        <span class="user-nav-text pl-2">{{ company.name }}</span>
      </template>

        <b-dropdown-text tag="div" class="topnav-company-list-wrapper">
          <b-form-input v-model="filterText" @input="filterCompany" placeholder="" />
          <span class="ml-4">{{$t('HEADER.FILTER_HELP')}}</span>
        </b-dropdown-text>
        <b-dropdown-form class="topnav-company-list">
          <b-dropdown-item
            v-for="(c, i) in filtered"
            :key="`company-${i}`"
            @click="onSelectCompany(c)"
            :active="currentCompanyId === c.company_id"
          >
            <fa-icon :icon="company_icon(c.company_id)" class="mr-3" />
            {{ c.name }}
          </b-dropdown-item>
        </b-dropdown-form>
      </b-dropdown>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.topnav-company-list-wrapper {
  min-width: 250px;
  span {
    font-size: 0.8rem;
  }
}
.topnav-company-list {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}
ul {
  transform: translate3d(-121px, 36px, 0px) !important;
  background-color: red;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  SET_TITLES
} from '@/core/services/store/common.module';

import { setCurrentPeriodId, setCompanyList, getCurrentPeriods, getCurrentPeriod } from '@/core/services/companyAccess';
import { setCurrentCompanyId } from '@/core/services/initUserMemlist';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'KTMenu',
  data() {
    return {
      
      period: {},
      company: {},
      filterText: '',
      filtered: [],
      initialized: false
    };
  },
  props: {
    company_id: { type: String, default: '' },
    period_id: { type: Number, default: '' }
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters([
      'companies',
      'currentUserId',
      'currentUser',
      'periods',
      'currentCompanyId',
      'currentPeriodId',
      'sid'
    ]),

    dropdown_variant() {
      return this.sid === this.currentCompanyId ? "outline-success" : "outline-primary";
    }
  },
  mounted() {
    
  },
  watch: {
    currentCompanyId(newValue) {
      if (newValue && this.companies) {
        this.company = this.companies.find(item => item.company_id === newValue);  
      }
    },
    companies(newValue, oldValue) {
      if (newValue && this.currentCompanyId) {
        this.company = newValue.find(item => item.company_id === this.currentCompanyId);
      }
      this.filtered = newValue;
    },
    currentPeriodId(newValue, oldValue) {
      // if current period is assigned first, make sure the periods is set
      if (newValue && this.periods) {
        this.period = this.periods.find(item => item.id === newValue);
      }
    },
    periods(newValue) {
      // if periods is assigned first, make sure the current period is set
      if (newValue && this.currentPeriodId) {
        this.period = newValue.find(item => item.id === this.currentPeriodId);
      }
    }
  },
  methods: {
  
    company_icon(company_id) {
      return this.sid === company_id ? ['fas', 'landmark'] : ['fas', 'building'];
    },

    setCompanyListInternal(companies) {

      var comp = [];

      companies.forEach(c => {
        comp.push(c);
      });


      if (comp.length > 0) {
        if (this.company_id) {
          const c = comp.find(x => x.company_id === this.company_id);

          if (c) {
            this.onSelectCompany(c);
          } else {
            this.onSelectCompany(comp[0]);
          }
        } else {
          this.onSelectCompany(comp[0]);
        }
      }

      this.filtered = comp;

      this.initialized = true;
    },
    async onSelectCompany(c) {
      const res = await setCurrentCompanyId(c.company_id, this.initialized);

      if (!res) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ändra förening');
      }
      else {
        this.toastr('success', this.$t('COMMON.OK'), 'Förening ändrad');
      }
    },
    async onSelectPeriod(p) {
      setCurrentPeriodId(p.id, this.initialized);
      this.period = getCurrentPeriod();
    },
    filterCompany(e) {
      if (this.filterText === '' || this.filterText === ' ') {
        this.filtered = this.companies;
      } else {
        this.filtered = this.companies.filter(x =>
          x.name.toUpperCase().includes(this.filterText.toUpperCase())
        );
      }
    },
    
  }
};
</script>
